import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import MinimalLayout from "../layouts/MinimalLayout";
import VideoView from "../views/VideoView";
import SettingsView from '../views/SettingsView';
import SummaryView from '../views/SummaryView';
import MicrophoneView from '../views/MicrophoneView';
import SpeakerView from '../views/SpeakerView';

const MainRoutes: RouteObject = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element : <SettingsView />
        },
        {
            path: '/video',
            element: <VideoView />
        },
        {
            path: '/microphone',
            element: <MicrophoneView />
        },
        {
            path: '/speaker',
            element: <SpeakerView />
        },
        {
            path: '/summary',
            element: <SummaryView />
        },
        { path: '*',  element:  <Navigate to='/' replace /> }
    ]
};


export default function Routes() {
    return useRoutes([MainRoutes]);
}