import { RemoteDeviceOverlayProps } from "../utils/types";

const RemoteDeviceOverlay = ({activeMic, lvl}: RemoteDeviceOverlayProps) => {

    const generate = (lvl: any) => {
        const components:any = [];
        const l = Math.floor(100*lvl*2);
        const NUM = 18;

        if (lvl<0) return '';

        for (let i=0; i<NUM; i++) {
            const active = l > (100 / NUM)*i;
            const r = <div className={'circle ' + (active ? 'circle--active' : '') } key={i}></div>
            components.push(r);
        }

        return (
            <div className={'circle-container'}>{components}</div>
        )
    }


    return (
        <div className={'remoteDeviceOverlay'}>
            <div className={'remoteDeviceOverlay__smallText'}>Ausgewähltes Mikrofon:</div>

            {activeMic && <div className={'remoteDeviceOverlay__row'}>
                <div className={'remoteDeviceOverlay__micName'}>{activeMic}</div>
                <div className={'remoteDeviceOverlay__lvl'}>{generate(lvl)}</div>
            </div>}

        </div>
    );
}

export default RemoteDeviceOverlay;