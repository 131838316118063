import { createContext, useContext } from "react";
import { TGlobalContext } from "./utils/types"


const GlobalState = createContext<TGlobalContext>({
    state: {},
    updateState: () => {}
});

export function useCtx() {
    return useContext<TGlobalContext>(GlobalState);
}

export default GlobalState;
