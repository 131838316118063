import config from "./config";
import {TJwt} from "./types";
import {useQuery} from "react-query";


export function getJwtEndpoint(roomName: string) {
    let prefix = config.backend || '';
    if (prefix.endsWith('/')) prefix = prefix.substring(0, prefix.length-1);
    return `${prefix}/jwt/${roomName}`;
}

export function generateRoomName() {
    return `${config.roomPrefix}${crypto.randomUUID()}`
}

export function useJwt(room: string, queryEnabled:boolean) {

    const  getJwt = async (roomName: string):Promise<TJwt> =>  {
        const url = getJwtEndpoint(roomName);

        const requestOptions: RequestInit = {
            method: 'GET'
        };

        // debug token to use without a backend
        if (config.jitsiJWT) {
            return {
                jwt: config.jitsiJWT
            }
        }

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            throw new Error('jwt fetch fail');
        }

        return response.json();
    }

    const { isLoading, isError, data, refetch } = useQuery<TJwt, Error>(
        ['jwt', room],
        () => getJwt(room),
        {
            enabled: queryEnabled,
            staleTime: Infinity,
            cacheTime: 1,
            refetchOnMount: false,
            retryOnMount: false,
            retry: false
        }
    );

    return {
        jwt: data?.jwt,
        isLoading,
        isError,
        refetch
    }

}