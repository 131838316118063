import { Grid } from "@mui/material";
import { useJitsiLoader } from "../utils/jitsiUtils";
import { useState } from "react";
import { JitsiVideoFrameProps } from "../utils/types";
import config from "../utils/config";


const JitsiRemoteVideoFrame = ({ room, jwt, onJoin}: JitsiVideoFrameProps) => {
    const [node, setNode] = useState<any>();

    const remVideoOptions = {
        roomName: room,
        width: '100%',
        height: '100%',
        parentNode: node,
        jwt: jwt,
        configOverwrite: {
            connectionIndicators: {
                autoHide: false,
            },
            disableInviteFunctions: true,
            disableModeratorIndicator: true,
            disableProfile: true,
            disableRemoteMute: true,
            disableShortcuts: true,
            disableTileView: true,
            doNotStoreRoom: true,
            enableInsecureRoomNameWarning: false,
            filmstrip: {
                disableResizable: true,
                disableStageFilmstrip: true,
                disableTopPanel: true,
            },
            hideConferenceSubject: true,
            hideConferenceTimer: true,
            hideDisplayName: true,
            hideParticipantsStats: true,
            notifications: [],
            p2p:   {
                enabled: false,
            },
            prejoinConfig: {
                enabled: false,
            },
            remoteVideoMenu: {
                disabled: true,
            },
            requireDisplayName: false,
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            tileView: {
                disabled: true,
            },
            toolbarButtons: [],
        },
        interfaceConfigOverwrite: {
            DISABLE_VIDEO_BACKGROUND: true,
            VERTICAL_FILMSTRIP: true,
        },
    };

    const { isLoading, isError  } = useJitsiLoader({
        options: remVideoOptions,
        onJoin: (api: any) => {
            console.log(`JOINED VIDEO CONFERENCE remote ${api._myUserID}`);

            function closeFilmStrip(e:any, api:any) {
                if (e.visible) api.executeCommand('toggleFilmStrip');
            }

            api.addListener('filmstripDisplayChanged', (e:any) => closeFilmStrip(e, api));
            api.executeCommand('setTileView', false);

            if (onJoin) onJoin();
        },

        onEnd: () => {
            console.log('JITSI END CALLED');
            // setEnd(true)
        }
    });

    return (
        <div>
            <Grid item xs  sx={{backgroundColor: 'grey', width: '100%'}} >
                {isLoading && <h1>Loading</h1>}
                {isError && <h1>Error loading Jitsi Web application.</h1>}
                <div className={'jitsi-iframe'} ref={setNode}></div>
            </Grid>
        </div>
    );
}

export default JitsiRemoteVideoFrame;