import { DeviceOverlayProps } from "../utils/types";

const DeviceOverlay = ({devices}: DeviceOverlayProps) => {

    const isActive = (device:any) => device.active;

    const generate = (lvl: any) => {
        const components:any = [];
        const l = Math.floor(100*lvl*1.1);
        const NUM = 30;

        for (let i=0; i<NUM; i++) {
            const active = l > (100 / NUM)*i;
            const r = <div className={'circle ' + (active ? 'circle--active' : '') } key={i}></div>
            components.push(r);
        }

        // components.push(<div key={'lvl'} style={{alignSelf: "flex-end"}}>{lvl}</div>)

        return (
            <div className={'circle-container'}>{components}</div>
        )
    }


    return (
        <div className={'deviceOverlay'}>
                <div key={'mic'} className={'text'} > Erkannte Mikrofone: </div>
                {devices.map((device:any) => {
                    return (
                        <div key={`lvl-${device.deviceId}`} className={'deviceOverlay__item ' + (isActive(device) ? 'deviceOverlay__item--active' : '')}>
                            <div key={'text'} className={'deviceOverlay__item__text'}>{`${device.label}`}</div>
                            {generate(device.lvl)}
                        </div>
                    )
                })}
        </div>
    );
}

export default DeviceOverlay;