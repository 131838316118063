import errors from "../components/error-modal/errors.json";


export function errorFromConfig(error: any) {
    const fromConfig = errors[error.name as keyof typeof errors];
    return fromConfig || {
        ...errors["OtherError"],
        description: error.message || errors["OtherError"].description,
        code: error.name || errors["OtherError"].code
    }
}