import { Grid } from "@mui/material";
import { useJitsiLoader } from "../utils/jitsiUtils";
import { useState } from "react";
import {JitsiMicrophoneFrameProps, TJwt} from "../utils/types";
import config from "../utils/config";
import {getJwtEndpoint} from "../utils/backendServices";
import {useQuery} from "react-query";
import {minutes} from "../utils/timeUtil";


const JitsiLocalSpeakerFrame = ({ room, onJoin, jwt}: JitsiMicrophoneFrameProps) => {
    const [ node, setNode ] = useState<any>();

    const locVideoOptions = {
        roomName: room,
        width: '100%',
        height: '100%',
        parentNode: node,
        jwt: jwt,
        configOverwrite: {
            hideConferenceSubject: true,
            prejoinConfig: {
                enabled: false,
            },
            startWithAudioMuted: false,
            startWithVideoMuted: false,
            toolbarButtons: [ `microphone` ],
            toolbarConfig: { 
                alwaysVisible: true
            }
        }
    }

    const { isLoading, isError  } = useJitsiLoader({
        options: locVideoOptions,
        onJoin: (api: any) => {
            console.log(`JOINED VIDEO CONFERENCE local ${api._myUserID}`);

            if (onJoin) onJoin();
        },

        onEnd: () => {
            console.log('JITSI END CALLED');
            // setEnd(true)
        }
    });

    return (
        <div>
            <Grid item xs  sx={{backgroundColor: 'grey', width: '100%', position:`relative` }} >
                {isLoading && <h1>Loading</h1>}
                {isError && <h1>Error loading Jitsi Web application.</h1>}
                <div className={'jitsi-iframe'} ref={setNode}></div>
            </Grid>
        </div>
    );
}

export default JitsiLocalSpeakerFrame;