import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import config from '../utils/config'

const Header = () => {
  return (
    <Grid className="header">
      <Link to={config.bmiLandingURL}>
        <img
          className="header__logo"
          src="images/general/BMJ_Logo_Videoportal_rgb.svg"
          alt="logo"
        />
      </Link>
    </Grid>
  )
}

export default Header
