import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonsGroupProps } from "../utils/types";
import config from "../utils/config";

const ButtonsGroup = ({ text, navigation } : ButtonsGroupProps) => {
    const url = new URL(window.location.href);

    const navigate = useNavigate();
   
    const clickNextHandler = () => navigate(navigation.next);
    
    const clickBackHandler = () => {
        if (url.searchParams.has("location") && url.searchParams.has("room")) {
            window.location.href = `${config.bmiLandingURL}/${url.searchParams.get("location")}/${url.searchParams.get("room")}`;
            return;
        }

        window.location.href = config.bmiLandingURL;
    }

    return (
        <Stack alignItems={{ xs : 'center' }} spacing={2} direction={{ xs: 'column', md: 'row' }} className="buttons-group">
            <Button className="button-back" variant="outlined" onClick={clickBackHandler}>{text.back}</Button>
            <Button className="button-next" variant="contained" onClick={clickNextHandler}>{text.next}</Button>
        </Stack>
    );
}


export default ButtonsGroup;