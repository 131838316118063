import { Divider, Grid, Typography, Stack, Button } from "@mui/material";
import BottomPanel from "../components/BottomPanel";
import {useNavigate} from "react-router-dom";
import Header from "../components/Header";
import config from "../utils/config";
import { useCtx } from "../context";

const SummaryView = () => {
    const url = new URL(window.location.href);

    const navigate = useNavigate();
    const globalCtx = useCtx();
   
    const clickBackHandler = () => navigate("/video");
    
    const clickNextHandler = () => {
        if (url.searchParams.has("location") && url.searchParams.has("room")) {
            window.location.href = `${config.bmiLandingURL}/${url.searchParams.get("location")}/${url.searchParams.get("room")}`;
            return;
        }

        window.location.href = config.bmiLandingURL;
    }
    
    return (
        <Grid className="end-page">
            <Header />
            <Grid className="main-end-page">
                <Grid className="title">
                    <Typography>Wie gut ist Ihre Verbindung?</Typography>
                </Grid>
                <Grid className="sub-title">
                    <Typography>Sie haben Ihre Verbindung und angeschlossenen Geräte getestet.</Typography>
                </Grid>
                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} className="end-page-content">
                    <Grid>
                        <Typography className="strong-text">Ist die Qualität ausreichend?</Typography>
                        <Grid>
                            <Typography className="text sub-text">Dann kehren Sie zurück zur Startseite.</Typography>
                            <Typography className="text">Wenn Ihr Gerichtstermin nicht gleich beginnt, schließen Sie das Browserfenster. Planen Sie genügend Zeit ein, um vor Ihrem Gerichtstermin den Test erneut zu starten und die Verbindungsqualität herzustellen.</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography className="strong-text">Ist Ihre Verbindung in Bild und/oder Ton zu schlecht?</Typography>
                        <Grid>
                            <Typography className="text">Folgen Sie den Anweisungen des Tests und holen Sie sich ggf. Unterstützung in der Einrichtung Ihrer Geräte.</Typography>
                            <Typography className="red-text">Sollte Ihnen eine Teilnahme an Ihrem Gerichtstermin aus technischen Gründen nicht möglich sein, informieren Sie bitte das Gericht rechtzeitig über diesen Kontakt:</Typography>
                            <Typography className="text text-data">{globalCtx.state.court_name}</Typography>
                            <Typography className="text text-data">{globalCtx.state.contact_phone}</Typography>
                            <Typography className="text text-data">{globalCtx.state.contact_mail}</Typography>
                            <Typography className="red-text">Der Termin wird dann in aller Regel zeitgleich vor Ort stattfinden.</Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Divider className="divider" />
                <Stack alignItems={{ xs : 'center' }} spacing={2} direction={{ xs: 'column', md: 'row' }} className="buttons-group">
                    <Button className="button-back" variant="outlined" onClick={clickBackHandler}>zurück zum Teststart</Button>
                    <Button className="button-next" variant="contained" onClick={clickNextHandler}>zurück zur Startseite</Button>
                </Stack>
                <Grid className="footer"></Grid>
                <BottomPanel/>
            </Grid>
        </Grid>
    )
}


export default SummaryView;