import { createTheme } from "@mui/material";


const theme = createTheme({
    palette: {
        background: {
            default: 'white'
        }
    }
});

export default theme;