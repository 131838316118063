import { useEffect, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import GlobalState from './context';
import { ReactQueryDevtools } from "react-query/devtools";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Routes from "./routes/routes";
import theme from "./themes/theme";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 2,
            staleTime: 0
        },
    },
});

function App() {
  const [state, updateState] = useReducer((state: any, next: any) => {
    return {...state, ...next};
  }, {});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const courtName = params.get('court_name');
    const contactPhone = params.get('contact_phone');
    const contactMail = params.get('contact_mail');

    updateState({
      court_name: courtName,
      contact_phone: contactPhone,
      contact_mail: contactMail,
    });
  }, []); // Empty dependency array ensures this runs only once on mount


  return (
      <GlobalState.Provider value={{state, updateState}}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes/>
          </ThemeProvider>
        </QueryClientProvider>
      </GlobalState.Provider>
  );
}

export default App;
