// provided fn may change on every render, but the returned function is stable
import { useCallback, useLayoutEffect, useEffect, useRef, useState } from "react";
import { TFn } from "./types"


export function useStableCallback(fn: TFn): TFn {
    const ref: any = useRef(fn);

    useLayoutEffect(() => {
        ref.current = fn;
    });

    return useCallback<TFn>(
        (...args: any[]) => ref.current.apply(void 0, args),
        []
    );
}


export function useMediaAllowAccess() {
    const [mediaAllowed, setMediaAllowed] = useState(false);
    const [mediaError, setMediaError] = useState<string | null>(null);

    useEffect(() => {
        const constraints = {
            audio: true,
            video: true,
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(() => setMediaAllowed(true))
            .catch((error) => {
                switch (error.name) {
                    case 'OverconstrainedError':
                        setMediaError(error);
                        console.error(
                            `Constraints not supported by your device.`,
                        );
                        break;
                    case 'NotAllowedError':
                        setMediaError(error);
                        console.error(
                            "You need to grant this page permission to access your camera and microphone.",
                        );
                        break;
                    case 'NotReadableError':
                        setMediaError(error);
                        console.error(`NotReadableError:`, error);
                        break;
                    default:
                        setMediaError(error);
                        console.error(`getUserMedia error: ${error.name}`, error);
                }
        });
    }, []);

    return {
        mediaAllowed,
        mediaError
    };
}