import { Box, Button } from '@mui/material'
import config from "../utils/config";

type BottomPanelType = {
  directionColumn?: boolean
}

const BottomPanel = ({ directionColumn }: BottomPanelType) => {
  return (
    <Box
      className={'bottom'}
    >
      <Box display="flex" sx={{
        flexDirection: directionColumn ? "column" : "row",
      }}>
        <Button
          className={'bottombutton'}
          href={config.landingPageUrl + '/pages/impressum.html'}
        >
          <Box>IMPRESSUM</Box>
        </Button>
        <Box className={'bottomseparator'} sx={{
          display: directionColumn ? "none !important" : "",
        }}>|</Box>
        <Button
          className={'bottombutton'}
          href={config.landingPageUrl + '/pages/datenschutz.html'}
        >
          <Box>DATENSCHUTZ</Box>
        </Button>
        <Box className={'bottomseparator'} sx={{
          display: directionColumn ? "none !important" : "",
        }}>|</Box>
        <Button
          className={'bottombutton'}
          href={config.landingPageUrl + '/pages/lizenzhinweise.html'}
        >
          <Box>LIZENZHINWEISE</Box>
        </Button>
      </Box>
    </Box>
  )
}

export default BottomPanel
