import {Box, LinearProgress} from "@mui/material";
import {ReactNode} from "react";

type JitsiFrameProps = {
    children: ReactNode,
    jwtError: boolean,
    jwtLoading: boolean,
    mediaAllowed: boolean,
    visible:boolean,
    mediaError: string | null
}

const JitsiFrameWrapper = ({jwtLoading, jwtError, children, mediaAllowed, mediaError, visible}:JitsiFrameProps) => {

    if (jwtError || mediaError) {
        return <Box sx={{width: '100%', height: '100%'}}><LinearProgress variant="determinate" color={"error"} value={50}/></Box>
    }

    if (jwtLoading) {
        return <Box sx={{width: '100%', height: '100%'}}><LinearProgress color={"secondary"}/></Box>
    }

    if (mediaAllowed && visible) {
        return <>{children}</>;
    }

    return <Box sx={{width: '100%', height: '100%'}}><LinearProgress/></Box>
}

export default  JitsiFrameWrapper;